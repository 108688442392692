import dynamic from "next/dynamic";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";

// Dynamic imports for your components
const FooterLink = dynamic(() => import("@/components/UI/FooterLink"), { ssr: true });
const Header = dynamic(() => import("@/components/Header"), { ssr: true });
const Footer = dynamic(() => import("@/components/UI/Footer"), { ssr: true });
const GapsInEducation = dynamic(
  () => import("@/features/home/components/desktop/v3/gapsInEducation"),
  { ssr: false }
);
const GapsInEducationMob = dynamic(
  () => import("@/features/home/components/mobile/v3/gapsInEducation"),
  { ssr: false }
);

const RecentPress = dynamic(
  () => import("@/features/home/components/recentPress"),
  { ssr: false }
);

const Layout = (WrappedComponent) => {
  return function LayoutComponent(props) {
    const router = useRouter();

    return (
      <Box
        sx={{
          minWidth:
            props?.device === "desktop"
              ? { xs: "992px", lg: "100%" }
              : { xs: "100%" },
        }}
      >
        <Header device={props?.device} userAgent={props.userAgent} />
        <Box mt={props?.device === "desktop" ? 10 : 8}>
          <WrappedComponent {...props} />
        </Box>
        {router.route === "/" && (
          <>
            {props?.device === "desktop" ? (
              <Box>
                <GapsInEducation />
              </Box>
            ) : null}
            {props?.device === "mobile" ? (
              <Box>
                <GapsInEducationMob />
              </Box>
            ) : null}

          </>
        )}
        <FooterLink />
        {router.route === "/" && (
        <Box sx={{ padding: "2em 0", borderTop:"2px solid #D7DDE5" }}>
              <RecentPress />
            </Box>
        )}

        <Footer />
      </Box>
    );
  };
};

export default Layout;
