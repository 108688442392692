import dynamic from 'next/dynamic';
import Layout from "@/components/Layout";
// import JOBSearch from "@/features/jobSearch/components";
import getFooterSearch from "@/features/jobSearch/apis/getFooterSearch";
import getSearchData from "@/features/jobSearch/apis/getJobSearch";
import getSearchDataByMeili from "@/features/jobSearch/apis/getJobSearchByMeili"
import getMeatDataForSearch from "@/features/jobSearch/hooks/getMeatDataForSearch";
// import DEVICE_REGEXP from "@/constant";
import DEVICE_REGEXP_NEW from "@/constant/deviceDetect";
import ssgAuth from "@/auth/ssgAuth";
import checkBot from '@/hooks/botCheck';

const JOBSearch = dynamic(() => import('@/features/jobSearch/components'));
const JOBSearchPage = Layout((props) => <JOBSearch {...props} />);

export async function getServerSideProps(ctx) {
  const { status, path } = await ssgAuth(ctx);

  if (status) {
    return {
      redirect: {
        destination: path,
        permanent: false,
      },
    };
  }

  const userAgent = ctx?.req?.headers["user-agent"];
  const device = DEVICE_REGEXP_NEW(ctx?.req);

  let queryString = ctx?.query,
    addSid = false;
  const { keyword, ...rest } = queryString;

  if (!queryString?.pageNo || !queryString?.sid) {
    queryString = {
      keyword: keyword?.replaceAll("-", " "),
      pageNo: 1,
      pageItems: 20,
      sort: "Freshness",
      ...rest,
    };
    addSid = true;
  } else {
    queryString = {
      keyword: keyword?.replaceAll("-", " "),
      ...rest,
    };
  }

  try {
    let res;
    let data;
    let searchApiStatusCode;

    if (checkBot(userAgent?.toString())) {
      console.log("🤖🤖🤖 BOT RESULTS ========================= \n");
      res = await getSearchDataByMeili(queryString);
      // res = await getSearchData(queryString);
    } else {
      console.log("🔎🔎🔎 JIE RESULTS ========================= \n");
      res = await getSearchData(queryString);
    }

    data = res.data;
    searchApiStatusCode = res.status;

    const { jobList, sid, sort, breadcrumb, seoContent, ...restData } =
      data?.data;

    const jobData = {
      jobList,
      sid,
      sort,
      breadcrumb: breadcrumb,
      seoContent: seoContent,
      pageData: { ...restData },
    };

    const footer = await getFooterSearch(queryString);

    const metaData = await getMeatDataForSearch(queryString, jobData)

    return {
      props: {
        queryString: queryString,
        searchData: jobData,
        suggestedData: footer?.data || [],
        seoContent: seoContent,
        addSid: addSid,
        device,
        userAgent: userAgent,
        metaData: metaData,
        searchApiStatusCode: searchApiStatusCode
      },
    };
  } catch (err) {
    console.log(err);
    if (
      queryString?.pageNo ||
      queryString?.sid ||
      queryString?.pageItems ||
      queryString?.sal
    ) {
      return {
        redirect: {
          destination: ctx?.query.keyword,
        },
      };
    } else {
      console.error("error on seo search page : : ", err);
      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    }
  }
}

export default JOBSearchPage;
